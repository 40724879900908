<template>
  <div>
    <div class="row mb-8">
      <div class="col-lg-2 mb-lg-0 mb-6">
        <label>Nhà mạng:</label>
        <select name="provider" id="type" class="form-control" v-model="filter.provider">
          <option value="">Tất cả</option>
          <option
            v-for="(value, key) in providers"
            v-bind:key="key"
            :value="key"
          >
            {{ value }}
          </option>
        </select>
      </div>

      <div class="col-lg-2 mb-lg-0 mb-6">
        <label>Loại dịch vụ:</label>
        <select name="type" id="type" class="form-control" v-model="filter.type">
          <option value="">Tất cả</option>
          <option v-for="(value, key) in actions" v-bind:key="key" :value="key">{{value}}</option>
        </select>

      </div>

      <div class="col-lg-4 mb-lg-0 mb-6">
        <label>Ngày khuyến mại:</label>
        <div class="input-daterange input-group" id="kt_datepicker">
          <input
            type="date"
            v-model="filter.start_date"
            class="form-control datatable-input"
            name="start"
            placeholder="Từ"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="la la-ellipsis-h"></i>
            </span>
          </div>
          <input
            type="date"
            v-model="filter.end_date"
            class="form-control datatable-input"
            name="end"
            placeholder="Đến"
          />
        </div>
      </div>

      <div class="col-lg-4 mb-lg-0 mt-6 text-right">
        <button type="reset" class="btn btn-default mr-2" @click="resetSearch()">Đặt lại</button>
        <button
          class="btn btn-primary btn-primary--icon"
          @click="createLinkSearch()"
        >
          <span><i class="la la-search"></i><span>Tìm kiếm</span></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "filterPromotion",
  props: ["providers", "actions"],
  data() {
    return {
      filter: {},
    };
  },
  components: {},
  mounted() {},
  methods: {
    createLinkSearch () {
      this.$emit("handleFilter", this.filter);
    },
    resetSearch() {
      this.filter = {};
      this.$emit("handleFilter", this.filter);
    },
  },
};
</script>
