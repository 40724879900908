<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <b-row>
                  <b-col><h6 class="mb-0">Danh sách khuyến mãi</h6></b-col>
                  <b-col class="col-md-4 text-right"><a class="btn btn-success" href="#/tools/promotion/create"><i class="fa fa-plus"></i> Thêm mới</a></b-col>
                </b-row>
              </template>
              <div class="filter-transaction">
                <filter-promotion :providers="providers" :actions="actions" @handleFilter="handleFilter"></filter-promotion>
              </div>
              <b-table :fields="fields" :items="items" details-td-class="datatable-cell" hover table-class="datatable-table" tbody-tr-class="datatable-row" thead-class="datatable-head" thead-tr-class="datatable-row">
                <!-- for default value -->
                <template v-slot:cell(is_active)="data">
                  <span v-if="data.value !== 0" class="text-primary">Hoạt động</span>
                  <span v-else class="text-danger">Tạm dừng</span>
                </template>
                <template #cell(custom)="data">
                  <a :href="`#/tools/promotion/update?id=${data.item.id}`" class="mr-2 text-primary">Sửa</a>
                  <a href="javascript:void(0)" @click="remove(data.item.id)" class="text-danger">Xoá</a>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav v-model="currentPage" :link-gen="createLinkPage" :number-of-pages="paginate.totalPage" use-router first-number last-number align="right"></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import filterPromotion from "@/view/pages/tools/promotion/components/filterPromotion.vue";
import CmsRepository from "@/core/repositories/cmsRepository";

export default {
  name: "Reconcile",
  mixins: [Common],
  components: {
    filterPromotion,
  },
  data() {
    return {
      filter: {},
      providers: {
        VIETTEL: "VIETTEL",
        VINAPHONE: "VINAPHONE",
        MOBIFONE: "MOBIFONE",
        GMOBILE: "GMOBILE",
      },
      actions: {
        TOPUP_MOBILE: "Nạp tiền điện thoại",
        TOPUP_DATA: "Nạp data 3G/4G",
      },
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "provider",
          label: "Nhà mạng",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "type",
          label: "Loại dịch vụ",
          formatter: "handleType",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "start_date",
          label: "Ngày bắt đầu",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "end_date",
          label: "Ngày kết thúc",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "is_active",
          label: "Trạng thái",
          tdClass: "datatable-cell text-center",
          thClass: "datatable-cell text-center",
        },
        {
          key: "custom",
          label: "Hành động",
          tdClass: "datatable-cell text-center",
          thClass: "datatable-cell text-center",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  watch: {
    currentPage() {
      this.getList();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Danh sách khuyến mãi" }]);
    this.filter = this.$route.query;

    this.getList();
  },
  methods: {
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.page = this.currentPage;
        params.limit = this.paginate.limit;
        let response = await CmsRepository.listPromotion(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        this.items = body.data.data;

        this.paginate.total = body.data.paging.total;
        this.paginate.totalPage = body.data.paging.total_page;
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    handleFilter(filter) {
      this.filter = filter;
      this.getList();
    },
    handleType(value) {
      return this.actions[value] !== "undefined" ? this.actions[value] : value;
    },
    remove(id) {
      if (!confirm("Bạn chắc chắn muốn xóa chứ?")) {
        return;
      }

      let self = this;
      CmsRepository.removePromotion(id)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (response.status !== 200) {
            alert(response.message);
            return false;
          }
          let result = response.data;
          if (result.error_code !== 0) {
            alert(result.message);
            return false;
          }

          self.getList();
        })
        .catch(() => {
          alert("Có lỗi xảy ra, vui lòng thử lại");
          this.$bus.$emit("show-loading", false);
        });
    },
  },
};
</script>
